<template>
    <div class="home">
        <div class="infoCards">
            <div
                v-for="(perk, index) in perks"
                :key="perk.header + index"
                class="infoCard"
            >
                <h2>
                    <!--                    <font-awesome-icon-->
                    <!--                        class="icon"-->
                    <!--                        :icon="['fa', 'paint-brush']"-->
                    <!--                    />-->

                    {{ perk.header }}
                </h2>

                <p>
                    {{ perk.text }}
                </p>
            </div>
        </div>

        <div class="productInfo">
            <h2>Professionele producten</h2>

            <p>
                Ik werk uitsluitend met professionele producten waarvan ik de
                kwaliteit kan garanderen en daardoor garantie kan geven voor een
                goed eindresultaat.
            </p>
        </div>

        <div class="aboutWrapper">
            <div class="aboutInfo">
                <div class="aboutMe">
                    <h2>
                        Over mij
                    </h2>

                    <p>
                        Ik ben Marco van der Kooij, geboren in 1971 in Utrecht
                        en hier ook altijd gewoond. Samen met mijn vrouw hebben
                        we drie kinderen, 2 jongens en een meisje.
                    </p>

                    <p>
                        Ooit als 16 jarige begonnen bij een schilder/glasbedrijf
                        als schilder. Hierna bij diverse grote en kleine
                        schilders en bouwbedrijven gewerkt.
                    </p>

                    <p>
                        Nu ga ik door als zelfstandige!
                    </p>
                </div>

                <img src="../assets/marco1.png" />
            </div>
        </div>

        <div class="checklist">
            <h2>
                Waarom ik?
            </h2>

            <div class="checklistItems">
                <div
                    class="checklistItem"
                    v-for="(item, index) in checklistItems"
                    :key="index"
                >
                    <br />
                    {{ item }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {
            perks: [
                {
                    header: 'Schilderwerk',
                    text:
                        'Je kunt bij mij terecht voor zowel binnen- als buiten schilderwerk. Ook lever ik saus en spuitwerk. Weet je nog niet zeker welke kleur je wilt? Ik geef graag kleuradvies!',
                },
                {
                    header: 'Glasweefselbehang',
                    text:
                        'Je kunt bij mij ook terecht voor het aanbrengen van glasweefselbehang.',
                },
                {
                    header: 'Houtrotherstel',
                    text:
                        'Heb je last van houtrot? Waar mogelijk is herstel ik dit graag voor je. Valt dit niet te herstellen los ik dit graag in overleg op.',
                },
            ],
            checklistItems: [
                'Vakbekwaamheid',
                'Kwaliteitswerk',
                'Gratis en duidelijke offerte',
                'Ervaring',
                'Vakkundig (kleur-)advies',
                'Nazorg',
            ],
        }
    },
    mounted() {
        this.$store.commit('toggleTemp', false)
    },
}
</script>

<style lang="scss" scoped>
.home {
    text-align: center;

    h2 {
        margin-bottom: 0.75rem;

        .icon {
            margin-right: 0.5rem;
            color: var(--marco-blue);
        }
    }

    p {
        font-weight: 300;
    }

    .infoCards {
        display: flex;
        justify-content: center;
        margin: -5rem auto 5rem auto;
        max-width: 125rem;

        .infoCard {
            width: 400px;
            text-align: center;
            margin: 2rem;
            padding: 3rem 3rem 6rem 3rem;
            border: 2px solid #bee0ee;
            border-radius: 25px;
            background: white;
        }
    }

    .productInfo {
        max-width: 50rem;
        margin: auto;

        h2 {
            margin-bottom: 0.75rem;
        }
    }

    .productInfoLogos {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        width: 50%;
        margin: auto;
        max-width: 50rem;

        .productInfoLogo {
            max-width: 200px;
            margin: 2rem auto;
        }
    }

    .aboutWrapper {
        background: linear-gradient(
            90deg,
            rgba(185, 222, 237, 0.25),
            rgba(239, 239, 239, 0.25)
        );

        .aboutInfo {
            display: flex;
            justify-content: space-between;
            max-height: 400px;
            margin: 7rem auto;

            img {
                object-fit: cover;
            }

            .aboutMe {
                margin: 80px;
                max-width: 50rem;
                text-align: left;

                h2 {
                    margin-bottom: 0.75rem;
                }

                p {
                    margin-bottom: 0.75rem;
                    font-weight: 300;
                }
            }
        }
    }

    .checklist {
        margin: 6rem auto;
        text-align: center;

        .checklistItems {
            display: grid;
            max-width: 800px;
            margin: auto;
            grid-template-columns: repeat(3, 1fr);
            list-style: none;

            .checklistItem {
                padding: 20px;
                font-weight: 300;
                font-size: var(--fs-m);
                box-sizing: border-box;

                .icon {
                    color: var(--marco-blue);
                    margin-right: 0.5rem;
                }

                &:not(:nth-child(3n)) {
                    /* Exclude right-most items */
                    border-right: 1px solid #bee0ee;
                }

                &:not(:nth-last-child(-n + 3)) {
                    /* Exclude bottom-row items */
                    border-bottom: 1px solid #bee0ee;
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .home {
        .banner {
            padding: 2.5rem;

            .bannerText {
                font-weight: normal;
                color: white;
                text-shadow: 0px 3px 6px #00000050;

                mark {
                    color: var(--marco-blue);
                    background: none;
                }
            }
        }

        .productInfo {
            width: 50%;
            max-width: 50rem;
            margin: auto;

            h2 {
                margin-bottom: 0.75rem;
            }
        }

        .productInfoLogos {
            display: block;
            margin: auto;

            .productInfoLogo {
                width: 33%;
                max-width: 7.5rem;
            }
        }

        .infoCards {
            display: flex;
            flex-wrap: wrap;
            max-width: 40rem;

            .infoCard {
                text-align: center;
                margin: 2rem;
                padding: 2rem;
                border-radius: 10px;
            }
        }

        .aboutWrapper {
            text-align: center;

            .icon {
                display: none;
            }

            .aboutInfo {
                display: block;
                grid-template-columns: 60% 40%;
                max-width: 100rem;
                padding: 2.5rem;
                text-align: center;
                margin: 2.5rem auto auto;

                img {
                    margin: auto;
                    max-width: 10rem;
                    border-radius: 10px;
                }

                .aboutMe {
                    margin: auto;
                    max-width: 50rem;
                    text-align: center;

                    h2 {
                        margin-bottom: 0.75rem;
                    }

                    p {
                        margin-bottom: 0.75rem;
                        font-weight: 300;
                    }
                }
            }
        }

        .checklistAndReviews {
            display: block;
            text-align: center;
            margin: 2.5rem auto;

            .checklist {
                text-align: left;
                margin: auto;
                width: 50%;

                h2 {
                    text-align: center;

                    .icon {
                        display: none;
                    }
                }

                ul {
                    list-style: none;

                    li {
                        font-weight: 300;
                        margin-bottom: 0.5rem;
                        font-size: var(--fs-s);

                        .icon {
                            margin-right: 0.5rem;
                        }
                    }
                }
            }

            .reviews {
                margin-top: 2.5rem;
                text-align: center;

                .icon {
                    display: none;
                }
            }
        }
    }
}
</style>
