<template>
    <div id="app">
        <MenuBar></MenuBar>
        <router-view />
        <Footer class="footer"></Footer>
    </div>
</template>

<script>
import MenuBar from '@/components/menubar/MenuBar'
import Footer from '@/components/footer/Footer'

export default {
    components: { Footer, MenuBar },
}
</script>

<style lang="css">
@import url('https://rsms.me/inter/inter.css');

:root {
    --fs-xl: 4rem;
    --fs-l: 2.5rem;
    --fs-m: 1.5rem;
    --fs-sm: 1.25rem;
    --fs-s: 1rem;
    --marco-blue: #bcdfed;
    --card-bg-color: #f3f3f3;
}

#app {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
}

html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.footer {
    margin-top: auto;
}

@supports (font-variation-settings: normal) {
    #app {
        font-family: 'Inter var', sans-serif;
    }
}

h1 {
    font-weight: normal;
    font-size: var(--fs-xl);
}

h2 {
    font-weight: normal;
    font-size: var(--fs-l);
}

h3 {
    font-weight: normal;
    font-size: var(--fs-m);
}

p {
    font-size: var(--fs-s);
}

@media (max-width: 1000px) {
    :root {
        --fs-xl: 2rem;
        --fs-l: 1.5rem;
        --fs-m: 1rem;
        --fs-s: 0.75rem;
    }
}
</style>
