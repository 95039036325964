<template>
    <footer class="footer">
        <div class="innerFooterWrapper">
            <div class="contact">
                <h2>Contact</h2>
                <p>
                    <font-awesome-icon
                        class="icon"
                        :icon="['fab', 'whatsapp']"
                    />
                    06-81845245
                </p>
                <p>
                    <font-awesome-icon
                        class="icon"
                        :icon="['fa', 'envelope']"
                    />
                    info@marcoschildert.nl
                </p>
            </div>

            <!-- TrustBox widget - Review Collector -->
            <div
                class="trustpilot-widget"
                data-locale="nl-NL"
                data-template-id="56278e9abfbbba0bdcd568bc"
                data-businessunit-id="67505f0aa6603aa8d15f80bd"
                data-style-height="52px"
                data-style-width="100%"
            >
                <a
                    href="https://nl.trustpilot.com/review/marcoschildert.nl"
                    target="_blank"
                    rel="noopener"
                    >Trustpilot</a
                >
            </div>
            <!-- End TrustBox widget -->

            <div></div>
        </div>
        <div class="credits">
            <p>© Marco Schildert 2024</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    methods: {
        openFB() {
            window.open('https://www.fb.com/marcoschildert', '_blank')
        },
    },
}
</script>

<style scoped lang="scss">
.footer {
    background: linear-gradient(
        90deg,
        rgba(185, 222, 237, 0.5),
        rgba(239, 239, 239, 0.5)
    );
    color: #333;
    padding: 2rem 10rem;

    .innerFooterWrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        max-width: 1000px;
        margin: 2rem auto;

        h2 {
            margin-bottom: 0.5rem;
        }

        .contact {
            margin: auto;

            .icon {
                margin-right: 0.75rem;
            }
        }

        .trustpilot-widget {
            width: fit-content;
            height: fit-content;
            margin: auto;
        }
    }

    .credits {
        border-top: 1px white solid;
        padding-top: 2rem;
        text-align: center;
        margin: 2rem auto;
    }
}

@media (max-width: 1024px) {
    .footer {
        padding: 1rem;

        .innerFooterWrapper {
            display: block;
            text-align: center;
            max-width: none;
            margin: auto;

            .contact {
                margin-bottom: 1rem;

                .icon {
                    margin-right: 0.5rem;
                }

                p {
                    text-align: center;
                }
            }
        }
    }
}
</style>
