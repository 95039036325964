<template>
    <div>
        <div class="topBar">
            <div class="topBarItems">
                <div class="topBarItem">
                    <font-awesome-icon
                        v-for="index in 5"
                        :key="index"
                        class="icon"
                        :icon="['fa', 'star']"
                    />

                    <p class="starsText">
                        5 sterren via

                        <a
                            href="https://nl.trustpilot.com/review/marcoschildert.nl"
                            target="_blank"
                        >
                            Trustpilot
                        </a>
                    </p>
                </div>
            </div>
        </div>

        <div class="menu">
            <div class="menuWrapper">
                <h2 class="logo">
                    Marco Schildert
                </h2>

                <div class="linksWrapper">
                    <div class="links">
                        <router-link to="/" exact>Home</router-link>

                        <router-link to="/contact" exact>Contact</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuBar',
}
</script>

<style lang="scss" scoped>
.topBar {
    padding: 20px 50px;

    .topBarItems {
        max-width: 1700px;
        margin: auto;
        display: flex;
        justify-content: space-between;

        .topBarItem {
            display: flex;

            .starsText {
                margin-left: 10px;
            }

            svg {
                color: var(--marco-blue);
            }
        }
    }
}

.menuWrapper {
    max-width: 1600px;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    border-radius: 50px;
    border: 2px solid white;
    background-color: rgba(white, 0.25);
    padding: 10px 50px;

    .logo {
        text-align: left;
        font-weight: 500;
        color: #333;

        mark {
            color: #333;
            font-weight: normal;
            background: none;
        }
    }

    .linksWrapper {
        margin: auto 0;

        .links {
            text-align: right;
            margin: auto 0;

            a {
                color: #333;
                text-decoration: none;
                font-size: var(--fs-sm);
                margin-left: 2rem;
            }

            a:hover {
                color: var(--marco-blue);
                transition: all 0.25s ease;
            }

            .router-link-active {
                color: var(--marco-blue);
            }
        }
    }
}

@media (max-width: 1024px) {
    .menuWrapper {
        width: 100%;
        display: block;
        text-align: center;
        padding: 20px 0;

        .logo {
            text-align: center;
            font-size: var(--fs-xl);
            padding: 0.5rem;
        }

        .linksWrapper {
            .links {
                text-align: center;
                display: grid;
                grid-template-columns: 50% 50%;
                padding: 0.5rem;
                border-radius: 10px;
                max-width: 15rem;
                margin: auto;

                a {
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
}

.banner {
    display: flex;
    margin-top: 50px;
    padding-left: 50px;

    .bannerText {
        font-weight: normal;
        color: #333;

        mark {
            color: var(--marco-blue);
            background: none;
        }
    }
}

.menu {
    padding: 4rem 6rem 10rem 6rem;
    background: linear-gradient(90deg, #b9deed, #efefef);
}
</style>
