<template>
    <div class="contact">
        <div class="innerContact">
            <div class="contactList">
                <h2>Contactinformatie</h2>

                <p>
                    <font-awesome-icon
                        class="icon"
                        :icon="['fa', 'map-marker-alt']"
                    />

                    Regio Utrecht
                </p>

                <div class="spacer" />

                <p>
                    <font-awesome-icon
                        class="icon"
                        :icon="['fab', 'whatsapp']"
                    />

                    06-81845245
                </p>

                <div class="spacer" />

                <p>
                    <font-awesome-icon
                        class="icon"
                        :icon="['fa', 'envelope']"
                    />

                    info@marcoschildert.nl
                </p>

                <div class="spacer" />

                <p class="fb" v-on:click="openFB">
                    <font-awesome-icon
                        class="icon"
                        :icon="['fab', 'facebook']"
                    />

                    Marco Schildert
                </p>

                <div class="spacer" />

                <p>
                    <font-awesome-icon
                        class="icon"
                        :icon="['fa', 'handshake']"
                    />

                    KvK: 84116196
                </p>

                <p>
                    <font-awesome-icon
                        class="emptyIcon"
                        :icon="['fa', 'handshake']"
                    />

                    BTW: NL003914445B80
                </p>

                <div class="buttons">
                    <button v-on:click="openWA">
                        <h3>
                            <font-awesome-icon
                                class="icon"
                                :icon="['fab', 'whatsapp']"
                            />

                            Direct Appen
                        </h3>
                    </button>

                    <button v-on:click="openMail">
                        <h3>
                            <font-awesome-icon
                                class="icon"
                                :icon="['fa', 'envelope']"
                            />

                            Direct Mailen
                        </h3>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact',
    methods: {
        openFB() {
            window.open('https://www.fb.com/marcoschildert', '_blank')
        },
        openMail() {
            window.open('mailto:info@marcoschildert.nl', '_blank')
        },
        openWA() {
            window.open('https://wa.me/31681845245', '_blank')
        },
    },
}
</script>

<style scoped lang="scss">
.contact {
    text-align: center;
    margin: -80px auto auto auto;

    .banner {
        padding: 5rem;
        background-image: url('../assets/banner.png');
        background-size: cover;

        .bannerText {
            font-weight: normal;
            color: white;
            text-shadow: 0px 3px 6px #00000050;
        }
    }

    h2 {
        margin-bottom: 0.75rem;
    }

    .innerContact {
        background-color: white;
        padding: 4rem;
        border: 2px solid #bee0ee;
        border-radius: 25px;
        max-width: 100rem;
        margin: 0 auto auto auto;

        .contactList {
            margin: 0 auto;
            text-align: left;

            .buttons {
                display: flex;
                flex-direction: column;
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                justify-content: center;

                button {
                    background-color: var(--marco-blue);
                    border: none;
                    border-radius: 10px;
                    padding: 1rem;
                    color: white;
                    width: 100%;
                    max-width: 15rem;
                    min-width: 200px;
                    margin: 0.5rem auto;
                    cursor: pointer;

                    .icon {
                        color: white;
                    }

                    h3 {
                        font-size: 1.25rem;
                    }
                }
            }

            @media (min-width: 1025px) {
                button:hover {
                    background-color: white;
                    outline: 2px var(--marco-blue) solid;
                    color: var(--marco-blue);
                    transition: all 0.3s ease;

                    .icon {
                        color: var(--marco-blue);
                    }
                }
            }

            .fb {
                cursor: pointer;
            }

            .fb:hover {
                color: var(--marco-blue);
                transition: all 0.25s ease;
            }

            .spacer {
                height: 1rem;
            }

            .icon {
                color: var(--marco-blue);
                margin-right: 0.75rem;
            }

            .emptyIcon {
                color: white;
                margin-right: 0.75rem;
            }
        }

        .contactForm {
            text-align: left;
        }
    }
}

@media (max-width: 1024px) {
    .contact {
        .banner {
            padding: 2.5rem;
        }

        .innerContact {
            display: block;
            margin: 2rem;

            .contactList {
                padding: 1rem;

                button {
                    width: 100%;
                    max-width: none;
                }
            }

            h2 {
                text-align: center;
            }

            p {
                text-align: center;
            }
        }
    }
}
</style>
